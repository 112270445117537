import Closed from './closed.svg'
import FlagRed from './flag_red.svg'
import Flag from './flag.svg'
import MineRed from './mine_red.svg'
import Mine from './mine.svg'
import Pressed from './pressed.svg'
import Type1 from './type1.svg'
import Type2 from './type2.svg'
import Type3 from './type3.svg'
import Type4 from './type4.svg'
import Type5 from './type5.svg'
import Type6 from './type6.svg'
import Type7 from './type7.svg'
import Type8 from './type8.svg'

const images = [Closed, FlagRed, Flag, MineRed, Mine, Pressed, Type1, Type2, Type3, Type4, Type5, Type6, Type7, Type8]

export default images
